export const ADFORMATS = [
  {
    type: "AD",
    title: "Trivia Ad Pod",
    slug: "trivia-ad-pod",
    banner: "https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/trivia-pod.png?alt=media&token=711301f1-d67c-4948-a40b-c32f7b2f2fb3",
    config: {
      atLaunch: "VIDEO",
      chatEnabled: false,
      brand: "DISNEY",
      deviceType: "CTV",
      device: {
        apple: {
          remoteImage: "https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/campaigns%2Fremote-apple-tv.png?alt=media&token=f16d702b-8716-4865-b488-d560bb5ea751",
        }
      },
      styles: {
        colorPrimary: "#1c77d2",
        colorSecondary: "#f66e1f"
      },
      background: "https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/living-room.jpg?alt=media&token=28b55473-ffcd-4911-bdf6-05b6407ef113",
    },
    campaign: {
      config: {
         leaderboardEnabled: true,
      },
      offer: {
        type: "DISCOUNT",
        title: "Answer right, get XX% off",
        amount: [ 0, 10, 25 ]
      },
      script: [
        {
          advertiser: "Lego",
          background: "https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/campaigns%2Flego-bg-2.jpg?alt=media&token=45791d24-131c-44b7-b407-1e07da7236b6",
          cta: [
            {
            type: "REMOTE",
            title: "It's Trivia Time!",
            description: "Use your remote to play now",
            },
          ],
          logo: "https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/campaigns%2Flogo-asset.png?alt=media&token=c53f8985-57b6-4f96-98f4-2ba2437592a3",
          message: {
            description: "Presented By",
            title: "Play and Win"
          },
          offer: {
            amount: [ 0, 10, 25],
            title: "Answer right, get 25% off.",
            type: "DISCOUNT",
          },
          slug: "avengers-endgame-trivia-edition",
          time: 10,
          trivia: [
            "poll-cl4bkig6j00003f6dipn34343z",
            "poll-dl4bkig6j00003f6dipn34343z",
            "poll-cl5bkig6j00003f6dipn34343z",
          ],
          type: "TRIVIAADPOD",
        },
      ]
    },
    video: {
      config: {
        time: 8,
      },
      genres: ["action", "drama"],
      releaseYear: 2019,
      source: "https://stream.mux.com/Yvjx802b2OkwWe5s5tyiKD00C3Ei3YkHA8WYc49ne49fM.m3u8",
      title: "Avengers Endgame",
      description: "After the devastating events of Avengers: Infinity War (2018), the universe is in ruins. With the help of remaining allies, the Avengers assemble once more in order to reverse Thanos' actions and restore balance to the universe.",
    },
  },
  {
    type: "AD",
    title: "Endcard Trivia Leaderboard",
    slug: "endcard-trivia-leaderboard",
    banner: "https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/trivia-pod.png?alt=media&token=711301f1-d67c-4948-a40b-c32f7b2f2fb3",
    config: {
      atLaunch: "VIDEO",
      chatEnabled: false,
      brand: "DISNEY",
      deviceType: "CTV",
      device: {
        apple: {
          remoteImage: "https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/campaigns%2Fremote-apple-tv.png?alt=media&token=f16d702b-8716-4865-b488-d560bb5ea751",
        }
      },
      styles: {
        colorPrimary: "#1c77d2",
        colorSecondary: "#f66e1f"
      },
      background: "https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/living-room.jpg?alt=media&token=28b55473-ffcd-4911-bdf6-05b6407ef113",
    },
    campaign: {
      config: {
         leaderboardEnabled: true,
      },
      offer: {
        type: "DISCOUNT",
        title: "Answer right, get XX% off",
        amount: [ 0, 10, 25 ]
      },
      script: [
        {
          advertiser: "Lego",
          background: "https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/campaigns%2Flego-bg-2.jpg?alt=media&token=45791d24-131c-44b7-b407-1e07da7236b6",
          logo: "https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/campaigns%2Flogo-asset.png?alt=media&token=c53f8985-57b6-4f96-98f4-2ba2437592a3",
          message: {
            description: "Presented By",
            title: "Play and Win"
          },
          achievement: {
            badge: "https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/campaigns%2Fbadge.png?alt=media&token=957a726a-1af3-4d0e-bbac-a225ed8dcf19",
            title: "Marvel Lego Quiz"
          },
          offer: {
            amount: [ 0, 10, 25],
            title: "Answer right, get 25% off.",
            type: "DISCOUNT",
          },
          slug: "avengers-endgame-trivia-edition",
          time: 10145,
          type: "ENDCARD-TRIVIA",
        },
      ]
    },
    video: {
      config: {
        time: 10140,
      },
      genres: ["action", "drama"],
      releaseYear: 2019,
      source: "https://stream.mux.com/Yvjx802b2OkwWe5s5tyiKD00C3Ei3YkHA8WYc49ne49fM.m3u8",
      title: "Avengers Endgame",
      description: "After the devastating events of Avengers: Infinity War (2018), the universe is in ruins. With the help of remaining allies, the Avengers assemble once more in order to reverse Thanos' actions and restore balance to the universe.",
    },
  },
  {
    type: "AD",
    title: "Pause Ad",
    slug: "pause-ad",
    config: {
      timeToDisplay: 5,
      playerControls: "DIM",
      atLaunch: "VIDEO",
      chatEnabled: false,
      brand: "DISNEY",
      deviceType: "CTV",
      device: {
        apple: {
          remoteImage: "https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/campaigns%2Fremote-apple-tv.png?alt=media&token=f16d702b-8716-4865-b488-d560bb5ea751",
        }
      },
      styles: {
        colorPrimary: "#1c77d2",
        colorSecondary: "#f66e1f"
      },
      background: "https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/living-room.jpg?alt=media&token=28b55473-ffcd-4911-bdf6-05b6407ef113",
    },
    video: {
      config: {
        time: 8900,
      },
      genres: ["action", "drama"],
      releaseYear: 2019,
      source: "https://stream.mux.com/Yvjx802b2OkwWe5s5tyiKD00C3Ei3YkHA8WYc49ne49fM.m3u8",
      title: "Avengers Endgame",
      description: "After the devastating events of Avengers: Infinity War (2018), the universe is in ruins. With the help of remaining allies, the Avengers assemble once more in order to reverse Thanos' actions and restore balance to the universe.",
    },
    campaign: {
      config: {
        initialLayout: "RIGHT-RAIL",
        type: "TRIVIA-VARIANT-1",
      },
      script: [
        {
          type: "PAUSE-AD",
          advertiser: "Generic Skincare",
          url: "skincareshoppe.com",
          qrCode: "https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/campaigns%2FSkincare%201.png?alt=media&token=10bb2527-7667-4530-885c-323344f4571d",
          background: "https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/campaigns%2Flego-bg-2.jpg?alt=media&token=45791d24-131c-44b7-b407-1e07da7236b6",
          scenes: [
            {
              type: "COMPACT",
              title: "Take a pause to test your skincare knowledge",
              logo: "https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/campaigns%2Fgeneric-brand-logo-light.png?alt=media&token=97749dd8-65ba-4205-b47f-12e575f36d55",
              featuredImage: "https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/campaigns%2Ffeatured-image.png?alt=media&token=79d1b2c0-3712-4cfa-ba4f-cf9fa5ecd5f0",
              cta: {
                image: "",
                type: "REMOTE",
                title: "Answer Question",
                action: 1,
              }
            },
            {
              type: "EXPANDABLE:TRIVIA:QUESTION",
              title: "Which product should you use first in your skin care routine?",
              logo: "https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/campaigns%2Fgeneric-brand-logo-dark.png?alt=media&token=c2cf57cd-748b-4c43-a21d-350e5529a687",
              background: "https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/campaigns%2Fbackground-default.png?alt=media&token=1b645354-d0f4-4648-85a6-083a0033ec75",
              options: [
                {
                  image: "https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/campaigns%2Foption-1.png?alt=media&token=4765f86b-c0d2-45a7-90b7-cda0e53ad62b",
                  title: "",
                  action: 2,
                },
                {
                  image: "https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/campaigns%2Foption-2.png?alt=media&token=9df5b83e-d9ce-44bf-b479-780afe6853ce",
                  title: "",
                  action: 3,
                },
                {
                  image: "https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/campaigns%2Foption-3.png?alt=media&token=f6bf3b7e-a5c6-40a2-b345-c6bfae5bc798",
                  title: "",
                  action: 2,
                },
              ]
            },
            {
              type: "EXPANDABLE:TRIVIA:TRYAGAIN",
              title: "❌ Nice try!",
              description: "8 out of 10 doctors recommend starting your routine with a cleanser.",
              offer: "Get 10% off all skincare",
              message: "Try again!",
              logo: "https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/campaigns%2Fgeneric-brand-logo-dark.png?alt=media&token=c2cf57cd-748b-4c43-a21d-350e5529a687",
              background: "https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/campaigns%2Fbackground-default.png?alt=media&token=1b645354-d0f4-4648-85a6-083a0033ec75",
              heroImage: "https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/campaigns%2Ffeatured-image-large-cropped.png?alt=media&token=0871aea3-a9de-4363-a43a-3a8d74a32bc7",
              heroImageTwo: "https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/campaigns%2Fflower%20background.png?alt=media&token=c2fc7512-176a-4a65-87e8-fecfb207c7c0",
              secondScreen: true,
              options: [
                {
                  image: "https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/campaigns%2Foption-1.png?alt=media&token=4765f86b-c0d2-45a7-90b7-cda0e53ad62b",
                  title: "",
                  action: 0,
                },
                {
                  image: "https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/campaigns%2Foption-2.png?alt=media&token=9df5b83e-d9ce-44bf-b479-780afe6853ce",
                  title: "",
                  action: 0,
                },
                {
                  image: "https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/campaigns%2Foption-3.png?alt=media&token=f6bf3b7e-a5c6-40a2-b345-c6bfae5bc798",
                  title: "",
                  action: 0,
                },
              ]
            },
            {
              type: "EXPANDABLE:TRIVIA:CORRECT",
              title: "🎉 Correct!",
              description: "8 out of 10 doctors recommend starting your routine with a cleanser.",
              offer: "Get 20% off all skincare",
              message: "Thanks for playing!",
              logo: "https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/campaigns%2Fgeneric-brand-logo-dark.png?alt=media&token=c2cf57cd-748b-4c43-a21d-350e5529a687",
              background: "https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/campaigns%2Fbackground-default.png?alt=media&token=1b645354-d0f4-4648-85a6-083a0033ec75",
              heroImage: "https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/campaigns%2Ffeatured-image-large-cropped.png?alt=media&token=0871aea3-a9de-4363-a43a-3a8d74a32bc7",
              heroImageTwo: "https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/campaigns%2Fflower%20background.png?alt=media&token=c2fc7512-176a-4a65-87e8-fecfb207c7c0",
              secondScreen: true,
              options: [
                {
                  image: "https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/campaigns%2Foption-1.png?alt=media&token=4765f86b-c0d2-45a7-90b7-cda0e53ad62b",
                  title: "",
                  action: 0,
                },
                {
                  image: "https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/campaigns%2Foption-2.png?alt=media&token=9df5b83e-d9ce-44bf-b479-780afe6853ce",
                  title: "",
                  action: 0,
                },
                {
                  image: "https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/campaigns%2Foption-3.png?alt=media&token=f6bf3b7e-a5c6-40a2-b345-c6bfae5bc798",
                  title: "",
                  action: 0,
                },
              ]
            },
          ],
        },
        {
          advertiser: "Lego",
          background: "https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/campaigns%2Flego-bg-2.jpg?alt=media&token=45791d24-131c-44b7-b407-1e07da7236b6",
          cta: {
            type: "REMOTE",
            title: "Trivia",
            description: "Learn More",
          },
          heroImage: "https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/campaigns%2Flego-spinning.gif?alt=media&token=f8016f32-f0a5-4a17-8df0-fa2b13a5883f",
          logo: "https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/campaigns%2Flogo-asset.png?alt=media&token=c53f8985-57b6-4f96-98f4-2ba2437592a3",
          message: {
            description: "Presented By",
            title: "Lego Trivia: Lego comes from the Danish term LEg GOdt. What do these words mean in English?",
          },
          trivia: [
            "Build Together",
            "Play Well",
            "Lets Go",
          ],
          type: "PAUSE-AD",
        },
      ]
    },
  },
];