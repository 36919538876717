import React, { useRef, useState, useEffect, useContext } from "react";
import { useParams } from "react-router";
import { UserContext } from "../../Context";

import "./PauseAd.scss";

import { db, analytics } from "../../modules/firebase";
import firebase from "firebase/compat/app";
import { useAuthState } from "react-firebase-hooks/auth";
import { useCollectionData } from "react-firebase-hooks/firestore";

import AdBadge from "../../components/Ads/AdBadge";

import { SceneCompactOverlay } from "../scenes/SceneCompactOverlay";
import { SceneExpandedOverlay } from "../scenes/SceneExpandedOverlay";

import { CSSTransition } from "react-transition-group";

function PauseAd({ campaign, currentButtonPress, viewable, paramOverride, delay = 2000 }) {
  const [isViewable, setIsViewable] = useState(viewable);
  const scenes = [SceneCompactOverlay, SceneExpandedOverlay];

  const { eventId } = useParams();
  const [currentActive, setCurrentActive] = useState(true);
  const [seconds, setSeconds] = useState(60);
  const [resultsSeconds, setResultsSeconds] = useState(30);
  const { currentUser } = useContext(UserContext);
  const hasRunInitialSetup = useRef(false);
  const isMounted = useRef(false);

  // Supported Scenes
  const [currentScene, setCurrentScene] = useState(scenes[0]);
  const [pauseState, setPauseState] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showPauseAd, setShowPauseAd] = useState(false); // Controls the delayed display of PauseAd
  const [currentAdButtonPress, setCurrentButtonPress] = useState(currentButtonPress);

  // Handle "Back" button press
  const handleButtonPress = (buttonKey) => {
    setCurrentButtonPress({ key: buttonKey });
  };

  useEffect(() => {
    if (!currentButtonPress) return;
  
    let timer;
  
    if (currentButtonPress.key === "Play/Pause") {
      setPauseState((prevPauseState) => {
        const newPauseState = !prevPauseState;
  
        if (newPauseState) {
          firebase.analytics().logEvent("User Engaged with Remote and Paused");
          firebase.analytics().logEvent("Pause Ad Impression");
  
          timer = setTimeout(() => {
            setShowPauseAd(true);
          }, delay);
        } else {
          firebase.analytics().logEvent("User Engaged with Remote and Unpaused");
          setShowPauseAd(false);
        }
  
        return newPauseState;
      });
    }
  
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [currentButtonPress]);  

  useEffect(() => {
    isMounted.current = true;

    if (!hasRunInitialSetup.current) {
      setTimeout(() => setShowPauseAd(true), 5000);
      hasRunInitialSetup.current = true;
    }
  }, [campaign]);

  return (
    <div className="pause-ad">
      <AdBadge />
      {/* Use CSSTransition for individual scenes, not the whole PauseAd */}
      <CSSTransition
        in={!isExpanded}  // Show CompactOverlay when not expanded
        timeout={500}
        classNames="fade"
        unmountOnExit
      >
        <div className="scene-compact-overlay-container">
          {campaign && !isExpanded && (
            <SceneCompactOverlay
              campaign={campaign.scenes[0]}
              setIsExpanded={setIsExpanded}
              setShowPauseAd={setShowPauseAd}
              useEffect={useEffect}
              currentButtonPress={currentButtonPress}
            />
          )}
        </div>
      </CSSTransition>
      
      <CSSTransition
        in={isExpanded}  // Show ExpandedOverlay when expanded
        timeout={500}
        classNames="fade"
        unmountOnExit
      >
        <div className="scene-expanded-overlay-container">
          {campaign && isExpanded && (
            <SceneExpandedOverlay
              campaign={campaign}
              setIsExpanded={setIsExpanded}
              setShowPauseAd={setShowPauseAd}
              useEffect={useEffect}
              currentButtonPress={currentButtonPress}
            />
          )}
        </div>
      </CSSTransition>
    </div>
  );
}

export default PauseAd;