import React, { useState, useEffect, useRef } from "react";
import { Button } from "@mui/material"; 
import "./SceneExpandedOverlay.scss";
import "../features/FeatureGatewayMessaging";
import { FeatureGatewayMessaging } from "../features/FeatureGatewayMessaging";

import firebase from "firebase/compat/app";
import { soundCorrect, soundIncorrect } from "../../helpers/constants";

export function SceneExpandedOverlay(props) {
  const { campaign, setIsExpanded = () => {}, setShowPauseAd = () => {}, currentButtonPress } = props || {};
  const [currentScene, setCurrentScene] = useState(null);

  // Sound Effects
  const audioCorrect = new Audio(soundCorrect);
  const audioIncorrect = new Audio(soundIncorrect);

  // References for list items (li elements)
  const liRefs = useRef([]);

  // State for focused item index
  const [focusedIndex, setFocusedIndex] = useState(0); // default to the first item
  const [focusedAction, setFocusedAction] = useState(null); // store the action for focused item

  // References for GatewayMessaging buttons
  const gatewayButtonRefs = useRef([]);
  
  // State for focused button index
  const [focusedGatewayButton, setFocusedGatewayButton] = useState(0); // default to the first Gateway button

  // Set focus to the first item when loading
  useEffect(() => {
    if (campaign && campaign.scenes && campaign.scenes.length > 1) {
      setCurrentScene(campaign.scenes[1]);
    }
  }, [campaign]);

  useEffect(() => {
    if (!currentButtonPress) return;

    handleKeyPress(currentButtonPress.key);
  }, [currentButtonPress]);

  // Set action for focused item
  // Set focus to the first GatewayMessaging button once it's rendered
  useEffect(() => {
    if (currentScene?.options && currentScene.options[focusedIndex]) {
      setFocusedAction(currentScene.options[focusedIndex].action);
    }

    if (currentScene?.secondScreen && gatewayButtonRefs.current.length > 0) {
      gatewayButtonRefs.current[0]?.focus(); // Set focus to the first button
    }
  }, [currentScene, focusedIndex]);

  const handleKeyPress = (key) => {
    if (key === "Back") {
      // When "Back" is pressed on ExpandedOverlay, collapse to CompactOverlay
      firebase.analytics().logEvent("User Went Back to Pause Ad Compact View");
      setIsExpanded(false);  // Collapse to CompactOverlay
      setShowPauseAd(true);  // Keep Pause Ad active until "Back" is pressed on CompactOverlay
    } else if (key === "ArrowLeft") {
      setFocusedIndex((prevIndex) => Math.max(0, prevIndex - 1)); // Move focus left, preventing out of bounds
    } else if (key === "ArrowRight") {
      setFocusedIndex((prevIndex) => Math.min(currentScene?.options.length - 1, prevIndex + 1)); // Move focus right, preventing out of bounds
    } if (key === "ArrowUp") {
      // Move focus to the previous Gateway button
      setFocusedGatewayButton((prevIndex) => Math.max(0, prevIndex - 1)); // Prevent going out of bounds
    } else if (key === "ArrowDown") {
      // Move focus to the next Gateway button
      setFocusedGatewayButton((prevIndex) => Math.min(gatewayButtonRefs.current.length - 1, prevIndex + 1)); // Prevent going out of bounds
    } else if (key === "Enter") {
      if (focusedAction) {
        handleOptionClick(focusedAction); // Trigger the function with the action of the focused item
      }
      if (focusedGatewayButton >= 0) {
        gatewayButtonRefs.current[focusedGatewayButton]?.click(); // Trigger button click on Enter
      }
    }
  };

  const handleOptionClick = (action) => {
    const nextScene = campaign.scenes[action];

    if (!nextScene) return; // Safety check

    setCurrentScene(nextScene);

    if (nextScene.type === "EXPANDABLE:TRIVIA:CORRECT") {
      audioCorrect.currentTime = 0; // Reset sound
      audioCorrect.play();
      firebase.analytics().logEvent("User Engaged with Pause Trivia and Was Right");
    } else if (nextScene.type === "EXPANDABLE:TRIVIA:TRYAGAIN") {
      audioIncorrect.currentTime = 0;
      audioIncorrect.play();
      firebase.analytics().logEvent("User Engaged with Pause Trivia and Was Wrong");
    }
  };

  return (
    <>
      {currentScene ? (
        <div
          className={
            "scene-expanded-overlay " +
            (currentScene.type !== "EXPANDABLE:TRIVIA:QUESTION" ? "two-column" : "")
          }
        >
          <img className="logo" src={currentScene.logo} alt="Scene Logo" />
          <div
            className="container"
            style={{ backgroundImage: `url(${currentScene.background})` }}
          >
            <div className="message-container">
              <h1 className="headline">{currentScene.title}</h1>
              <p>{currentScene.description}</p>

              <ul
                className="options"
                style={{
                  display: currentScene.type === "EXPANDABLE:TRIVIA:QUESTION" ? "flex" : "none",
                }}
              >
                {currentScene.options &&
                  currentScene.options.map((option, i) => (
                    <li
                      key={i}
                      className={`option ${focusedIndex === i ? "focused" : ""}`} // Add class for focused state
                      onClick={() => handleOptionClick(option.action)}
                      ref={(el) => liRefs.current[i] = el} // Store ref for each li element
                    >
                      {option.image && <img className="option-image" src={option.image} alt="" />}
                      {option.title && <label>{option.title}</label>}
                    </li>
                  ))}
              </ul>
              
              {currentScene.heroImage && <img className="hero-image" src={currentScene.heroImage} alt="" />}
              {currentScene.heroImage && (
                <div
                  className="hero-image-background"
                  style={{ backgroundImage: `url(${currentScene.heroImageTwo})` }}
                ></div>
              )}
            </div>

            {currentScene.type !== "EXPANDABLE:TRIVIA:QUESTION" && (
              <div className="rail">
                <h2>{currentScene.message}</h2>
                <p className="offer">{currentScene.offer}</p>
                {currentScene.secondScreen ? (
                  <FeatureGatewayMessaging 
                    cta={campaign.url} 
                    qrCode={campaign.qrCode} 
                    currentButtonPress={currentButtonPress}
                    buttonRefs={gatewayButtonRefs} // Pass button refs to GatewayMessaging
                  />
                ) : null}
              </div>
            )}
          </div>
        </div>
      ) : null}
    </>
  );
}