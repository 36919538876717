import { FeatureBrandMessage } from "../features/FeatureBrandMessage";
import './SceneCompactOverlay.scss'; 
import firebase from "firebase/compat/app";
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { Button } from "@mui/material";

export function SceneCompactOverlay(props) {
  const { campaign, setIsExpanded = () => {}, setShowPauseAd = () => {}, useEffect = () => {}, currentButtonPress } = props || {};

  // Ensure useEffect is always called
  useEffect(() => {
    if (!currentButtonPress) return;

    handleKeyPress(currentButtonPress.key);
  }, [currentButtonPress]);

  const handleExpand = () => {
    firebase.analytics().logEvent("User Engaged and Went to Pause Ad Expanded View");
    setIsExpanded(true);
  };

  const handleKeyPress = (key) => {
    if (key === "Enter") {
      handleExpand();
    } else if (key === "Back") {
      // When "Back" is pressed, fully dismiss the Pause Ad (only if not going back from ExpandedOverlay)
      firebase.analytics().logEvent("User Engaged with Remote and Unpaused");
      setShowPauseAd(false);
    }
  };

  if (!campaign) {
    return null;
  }

  return (
    <div className="scene-compact-overlay">
      <div className="container">
        <FeatureBrandMessage
          advertiser={campaign?.advertiser}
          logo={campaign?.logo}
          headline={campaign?.title}
          hero={campaign?.featuredImage}
        /> 
        {campaign?.cta && (
          <Button className="btn primary" onClick={handleExpand}>
            <OpenInFullIcon />
            {campaign.cta.title}
          </Button>
        )}
      </div>
      <div className="featured-background"></div>
    </div>
  );
};