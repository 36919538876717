import React, { useRef, useState, useEffect } from 'react';
import VideoJS from '../components/VideoJS';

import '../styles/VideoPlayer.scss';

import InteractiveContent from './InteractiveContent';

export const TestVideoPlayer = (props) => {
  const playerRef = React.useRef(null);
  const { onPlayerStateChange } = props;  // Callback to parent
  const [currentTime, setCurrentTime] = useState(props.currentTime);
  const videoSrc = props.video;
  const currentEvent = props.currentEvent;
  const [adHasVideo, setAdHasVideo] = useState(false);
  const [playerState, setPlayerState] = useState({
    autoplay: true,
    controls: true,
    controlsOverlay: true,
    responsive: true,
    fluid: true,
    fill: true,
    sources: [{
      src: videoSrc,
      type: 'application/x-mpegURL'
    }]
  });

  useEffect(() => {
     if (currentEvent && currentEvent.key === "Play/Pause" && playerRef.current) {
      const player = playerRef.current;

      if (player.paused()) {
        player.play();
      } else {
        player.pause();
      }
    }
  }, [currentEvent]);

  const onPlayerPause = () => {
    console.log('video player is paused');
  }

  const onPlayerStart = () => {
    console.log('video player has started');
  }
  
  const onPlayerEnd = (video) => {
    console.log('video player has ended');
  }

  const handlePlayerReady = (player) => {
    playerRef.current = player;
    player.currentTime(currentTime);
  
    const updatePlayerState = (state) => {
      if (onPlayerStateChange) {
        onPlayerStateChange(state);
      }
    };
  
    player.on('play', () => {
      updatePlayerState({ state: 'playing' });
    });
  
    player.on('pause', () => {
      updatePlayerState({ state: 'paused' });
    });
  
    player.on('ended', () => {
      updatePlayerState({ state: 'ended' });
    });
  
    player.on('waiting', () => {
      updatePlayerState({ state: 'buffering' });
    });
  
    player.on('seeking', () => {
      updatePlayerState({ state: 'seeking' });
    });
  
    player.on('seeked', () => {
      updatePlayerState({ state: 'seeked' });
    });
  
    player.on('dispose', () => {
      updatePlayerState({ state: 'disposed' });
    });
  
    player.on('timeupdate', () => {
      setCurrentTime(player.currentTime());
    });
  };

  return (
    <div className={(adHasVideo ? 'ad-video ' : '') + ` video-player`}>
      <VideoJS options={playerState} onReady={handlePlayerReady} />
    </div>
  );
};
