import './FeatureBrandMessage.scss';

export function FeatureBrandMessage({ advertiser, logo, headline, message, hero }) {

  return (
    <div className="feature-brand-logo-lockup">
      <div className="container">
        <img className="logo" src={logo} alt={advertiser} />
      </div>
      <h1 className="headline">{ headline }</h1>
      <div className="container center">
        <img className="hero" src={hero} alt={advertiser} />
      </div>
    </div>
  );
}