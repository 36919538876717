import React, { useContext, useEffect, useState, useRef } from "react";
import './FeatureGatewayMessaging.scss';
import { UserContext } from "../../Context";
import { Button, Snackbar, Alert } from "@mui/material";
import firebase from "firebase/compat/app";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';

export function FeatureGatewayMessaging({ cta, qrCode, currentButtonPress, buttonRefs }) {
  const { currentUser } = useContext(UserContext);

  // Refs for buttons
  const defaultButtonRefs = useRef([]);
  const gatewayButtonRefs = buttonRefs || defaultButtonRefs;

  // State for the focused button index
  const [focusedButtonIndex, setFocusedButtonIndex] = useState(0); 

  // Snackbar state for showing notifications
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");

  // Listen for currentButtonPress and update the focus accordingly
  useEffect(() => {
    if (!currentButtonPress) return;

    if (currentButtonPress.key === "ArrowDown") {
      setFocusedButtonIndex((prevIndex) => Math.min(gatewayButtonRefs.current.length - 1, prevIndex + 1));
    } else if (currentButtonPress.key === "ArrowUp") {
      setFocusedButtonIndex((prevIndex) => Math.max(0, prevIndex - 1));
    } else if (currentButtonPress.key === "Enter" && focusedButtonIndex >= 0) {
      gatewayButtonRefs.current[focusedButtonIndex]?.click(); 
      handleButtonPress(focusedButtonIndex);
    }
  }, [currentButtonPress, focusedButtonIndex, gatewayButtonRefs]);

  // Focus handling for buttons
  useEffect(() => {
    if (gatewayButtonRefs.current.length > 0) {
      gatewayButtonRefs.current[focusedButtonIndex]?.focus();
    }
  }, [focusedButtonIndex, gatewayButtonRefs]);

  // Handle button press
  const handleButtonPress = (index) => {
    const message = index === 0 
      ? `Offer sent to ${currentUser.chatName}'s phone!` 
      : "Offer sent to your account email!";

    if (index === 0) {
      firebase.analytics().logEvent("User Requested Push Notification");
    } else if (index === 1) {
      firebase.analytics().logEvent("User Requested Email");
    }

    setNotificationMessage(message);
    setSnackbarOpen(true);
  };

  return (
    <div className="feature-gateway-messaging">
      <div className="container center">
        <label className="call-to-action">Send offer notification to:</label>

        {/* Button 1: Phone */}
        <Button
          ref={(el) => (gatewayButtonRefs.current[0] = el)}
          className={`primary ${focusedButtonIndex === 0 ? 'focused' : ''}`}
          onClick={() => handleButtonPress(0)}
        >
          <PhoneAndroidIcon />{currentUser.chatName}'s Phone
        </Button>

        {/* Button 2: Email */}
        <Button
          ref={(el) => (gatewayButtonRefs.current[1] = el)}
          className={focusedButtonIndex === 1 ? 'focused' : ''}
          onClick={() => handleButtonPress(1)}
        >
          <MailOutlineIcon />Account Email
        </Button>

        {/* QR Code (optional) */}
        {qrCode && <img src={qrCode} className="qr" alt="QR Code" />}
        <label className="url">{cta}</label>
        <label className="disclaimer">
          By electing to receive a notification about this advertiser, you are agreeing to receive a communication from Disney.
        </label>

        {/* Snackbar Notification */}
        <Snackbar 
          open={snackbarOpen} 
          autoHideDuration={5000} 
          onClose={() => setSnackbarOpen(false)}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }} // Moves it to the top-right

        >
          <Alert onClose={() => setSnackbarOpen(false)} severity="success">
            {notificationMessage}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
}